import axios from 'axios';
import { IPaginationResponse } from './paginationResponse';
import { IPlayer } from '../../entities/player';

export type SortDirection = 'asc' | 'desc';

export async function getPlayers(
  orgID: number,
  page: number,
  searchTerm: string | undefined = undefined,
  sortDirection: SortDirection = 'desc',
): Promise<IPaginationResponse<IPlayer>> {
  let route = `/players?orgID=${orgID}&page=${page}&limit=10&sortBy=createdDate+${sortDirection}`
    + '&status=all';

  if (searchTerm) {
    route += `&discordId=${searchTerm}&steamId=${searchTerm}`;
  }

  const response = await axios.get<IPlayer[]>(route);
  return {
    data: response.data,
    totalPages: parseInt(response.headers['x-total-pages'], 10),
  };
}

export async function getPlayer(orgID: number, playerID: string): Promise<IPlayer | undefined> {
  try {
    const { data: [player] } = await axios.get<IPlayer[]>(
      `/players?orgID=${orgID}&playerID=${playerID}&status=all`,
    );

    return player;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status.toString().startsWith('4')) {
      return undefined;
    }

    throw err;
  }
}
