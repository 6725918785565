
import { defineComponent } from 'vue';

import { signUp } from '@/services/steamcord';
import { validateSignUpData } from '@/services/validation/auth';

export default defineComponent({
  data() {
    return {
      formError: '',
      loading: false,
      passwordAgain: '',
      signUpData: {
        fullName: '',
        email: '',
        password: '',
      },
      signUpErrors: {
        fullNameError: '',
        emailError: '',
        passwordError: '',
        hasErrors: false,
      },
    };
  },
  methods: {
    async onSubmit(): Promise<void> {
      if (this.loading) {
        return;
      }

      this.signUpErrors = validateSignUpData(this.signUpData, this.passwordAgain);
      if (this.signUpErrors.hasErrors) {
        return;
      }

      this.loading = true;

      try {
        await signUp(this.signUpData);
      } catch (err) {
        this.formError = 'Email address is already in use.';
        this.loading = false;
        return;
      }

      this.$router.push({ name: 'Sign Up Verify Email', params: { email: this.signUpData.email } });
    },
  },
});
