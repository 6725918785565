import axios from 'axios';
import { IApiToken } from '../../entities/apiToken';

export async function createApiToken(orgID: number, name: string): Promise<string> {
  const { data: token } = await axios.post<string>(`/tokens?orgID=${orgID}`, { name });

  return token;
}

export async function getApiTokens(orgID: number): Promise<IApiToken[]> {
  const { data: tokens } = await axios.get<IApiToken[]>(`/tokens?orgID=${orgID}`);
  return tokens;
}

export async function revokeApiToken(orgID: number, id: string): Promise<void> {
  await axios.delete(`/tokens/${id}?orgID=${orgID}`);
}
