import axios from 'axios';
import { ISettings } from '../../entities/settings';
import { IErrors, IErrorResponse } from './errors';

export async function updateSettings(
  orgID: number, settings: ISettings,
): Promise<[IErrors | undefined, ISettings | undefined]> {
  try {
    const { data: response } = await axios.put<ISettings>(`/settings?orgId=${orgID}`, settings);
    return [undefined, response];
  } catch (err) {
    if (axios.isAxiosError(err)) {
      return [(err.response?.data as IErrorResponse).errors, undefined];
    }

    throw err;
  }
}

export async function updateSettingsdiscordGuild(orgID: number, code: string): Promise<string> {
  const { data: guildID } = await axios.put<string>(`/settings/discord?orgId=${orgID}`, code, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return guildID;
}

export async function getSettings(orgID: number): Promise<ISettings> {
  const { data: settings } = await axios.get(`/settings?orgId=${orgID}`);
  return settings;
}
