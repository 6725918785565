import { ISignUpData } from '@/services/steamcord/auth';
import { IErrorAggregate } from './errorAggregate';
import { validate, validateEmail, requiredStringSchema } from '.';

const nameFormatMessage = 'Invalid characters.';
const passwordFormatMessage = 'Passwords must at least 8 characters with one capital letter and '
  + 'number.';

const validationSchema = {
  fullName: requiredStringSchema.matches(/^[a-zA-Z'\-. ]+$/, nameFormatMessage),
  password: requiredStringSchema.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)\S{8,}$/, passwordFormatMessage),
};

export interface ISignUpErrors extends IErrorAggregate {
  fullNameError: string;
  emailError: string;
  passwordError: string;
}

export function validatePasswords(password: string, passwordAgain: string): string {
  const error = validate(validationSchema.password, password);
  return error === '' && password !== passwordAgain
    ? 'Passwords must match.'
    : error;
}

export function validateSignUpData({ fullName, email, password }: ISignUpData,
  passwordAgain: string): ISignUpErrors {
  const errors = {
    fullNameError: validate(validationSchema.fullName, fullName),
    emailError: validateEmail(email),
    passwordError: validatePasswords(password, passwordAgain),
    hasErrors: false,
  };

  errors.hasErrors = !!(errors.fullNameError || errors.emailError || errors.passwordError);

  return errors;
}

export interface ISignInErrors extends IErrorAggregate {
  emailError: string;
  passwordError: string;
}

export function validateSignInData(email: string, password: string): ISignInErrors {
  const errors = {
    emailError: validate(requiredStringSchema, email),
    passwordError: validate(requiredStringSchema, password),
    hasErrors: false,
  };

  errors.hasErrors = !!(errors.emailError || errors.passwordError);

  return errors;
}
