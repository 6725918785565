import axios from 'axios';

export async function getCheckoutUri(orgID: number): Promise<string> {
  const { data: uri } = await axios.post<string>(`/subscriptions/checkout?orgID=${orgID}`);
  return uri;
}

export async function getBillingPortalUri(orgID: number): Promise<string> {
  const { data: uri } = await axios.post<string>(`/subscriptions/billing-portal?orgID=${orgID}`);
  return uri;
}
